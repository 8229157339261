@font-face {
    font-family: 'Avenir';
    src:  url('http://jestemzolza.pl/build/fonts/Avenir-Medium.ttf') format('ttf');
}
@font-face {
    font-family: 'Avenir';
    src:  url('http://jestemzolza.pl/build/fonts/Avenir-Black.ttf') format('ttf');
    font-weight: bold;
}

html {
    position: relative;
    min-height: 100%;
}
body{
    background: #F5F5F5;
    padding-top: 120px;
    margin-bottom: 90px;
}
img{
    max-width: 100%;
}
.container{
    position: relative;
}
@media (min-width: 1200px) {
    .container {
        width: 1100px;
    }
}

.navbar {
    min-height: 50px;
    border: 0;
    border-bottom: 1px solid transparent;
    border-radius: 0;
}
.navbar-brand > img {
    display: block;
    position: absolute;
    height: 55px;
    left: 50%;
    margin-left: -172px;
    margin-top: 10px;
}
.navbar-brand .fb-like {
    position: absolute;
    left: 50%;
    margin-left: -152px;
    bottom: 7px;
}
.navbar-brand {
    float: none;
    font-size: initial;
    line-height: initial;
}
.navbar-default {
    background-color: #fff;
    border-color: #e7e7e7;
}
.navbar-nav > li > a {
    line-height: 30px;
    font-family: Raleway,sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 12px;
    font-weight: 800;
    padding: 30px 30px;
}
.navbar-default .navbar-nav > li > a {
    color: #303030;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #303030;
    background-color: initial;
}
.navbar-default .navbar-nav > .active > a:after {
    content: "";
    width: 20px;
    height: 2px;
    display: block;
    background: #DE4C80;
    position: absolute;
    left: 50%;
    margin-left: -10px;
}
.addbtn > a > div {
    background: #DE4C80;
    color: #fff;
    padding: 5px;
    width: 150px;
}

.navbar-nav > li.addbtn > a {
    padding: 25px 0;
    line-height: 14px;
    letter-spacing: 1px;
}
.nav.navbar-left > li:first-child > a {
    margin-left: -30px;
}
.addbtn .fa-upload {
    line-height: 28px;
    font-size: 20px;
    float: left;
    margin-right: 5px;
}

span.light {
    font-weight: 100;
}
.post {
    padding: 30px;
    background: #fff;
    margin-bottom: 30px;
}
.post img{
    width: 100%;
    border: 1px solid #F5F5F5;
}
.post h2 {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}
.post h2:after {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background: #DE4C80;
    margin-top: 6px;
    margin-bottom: 30px;
}
.blog.post h2{
    font-size: 22px;
}
.blog h4{
    font-size: 18px;
}
.blog.post .content{
    font-size: 18px;
}
a{
    color: #303030;
}
a:hover, a:focus {
    color: #303030;
    text-decoration: none;
}
.social-buttons {
    margin-top: 20px;
}

.fb-buttons {
    display: inline-block;
    border: 1px solid #ddd;
    padding: 14px;
}

.comment-button {
    text-align: center;
    line-height: 48px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    border: 1px solid #e5e5e5;
    margin-left: 10px;
    padding: 0 10px;
}
.vote {
    font-size: 20px;
    line-height: 50px;
    text-align: right;
    color: #606774;
    font-family: "Open Sans",Raleway, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
}

.vote .fa {
    font-size: 37px;
    color: #DE4C80;
    line-height: 50px;
    vertical-align: bottom;
}
.vote .count {
    margin-right: 5px;
}
.post .image{
    position: relative;
}
a.next, a.prev{
    position: absolute;
    top: 50%;
    width: 29px;
    height: 74px;
    line-height: 74px;
    font-size: 20px;
    margin-top: -37px;
    text-align: center;
    background: #DE4C80;
    color: #fff;
}
a.next:hover, a.prev:hover{
    color: #fff;
}
a.next{
    left: 0;
}
a.prev{
    right: 0;
}
footer.main-footer {
    height: 90px;
    color: #fff;
    background: #303030;
    line-height: 30px;
    font-family: Raleway,sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 12px;
    font-weight: 100;
    padding: 30px 30px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.main-footer a {
    margin-left: 20px;
    color: #fff;
}
.main-footer a span{
    color: #DE4C80;
    font-weight: 700;
}
.mod-actions {
    position: absolute;
    top: 0;
    right: -170px;
    width: 160px;
}
.reldatepicker{
    margin-top: 5px;
}
.pagination > li > a, .pagination > li > span{
    margin-left: 5px;
    padding: 12px 20px;
    margin-bottom: 10px;
}
#fanpage{
    background: #fff;
    padding: 20px;
}
.lewy-sky,.prawy-sky{
    position: absolute;
    top: 300px;
    height: 600px;
    width: 160px;
}
.lewy-sky {
    left: 0;
}
.prawy-sky {
    right: 0;
}
.affix > div{
    position: fixed;
    top: 110px;
}
.contact-form{
    padding-top: 15px;
    padding-bottom: 15px;
    background: #fff;
    margin-bottom: 30px;
}
.next-prev{
    background: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;
}
.comments{
    background: #fff;
    margin-top: 30px;
    margin-bottom: 30px;
}
.next-prev-img{
    border: 1px solid #ddd;
}
.next-prev-btn{
    background: #DE4C80;
    height: 20px;
    width: 50%;
    text-align: center;
    margin-top: 5px;
    color: #fff;
    display: inline-block;
    text-transform: uppercase;
}
.next-prev-btn:hover {
    color: #fff;
    opacity: .8;
}
.tooltip-inner{
    font-size: 14px;
    width: 280px;
    max-width: 280px;
}
.next-prev .tooltip{
    left: 33px!important;
    z-index: 1020;
}
.nextpage{
    width: 100%;
    display: block;
    font-size: 22px;
    padding: 20px;
    background: #DE4C80;
    color: #fff;
}
.nextpage:hover{
    color: #fff;
    opacity: .8;
}
.nextpage .fa{
    margin-left: 20px;
}
.strzalki img{
    margin-top: 8px;
}
.billboard {
    margin-bottom: 25px;
}
.rect {
    margin-bottom: 25px;
}
.single-post .rect{
    margin-top: 30px;
    margin-bottom: 0;
}
.pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus{
    color: #fff;
    background: #DE4C80;
}
#populars img {
    width: 100%;
    margin-bottom: 15px;
}

#populars > div {
    background: #fff;
    padding: 15px 30px;
}
.fb-share{
    position: absolute;
    bottom: 18px;
    left: 18px;
    color: #fff;
    background: #DE4C80;
    font-size: 12px;
    width: 50px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-family: Roboto,"Helvetica Neue", Helvetica, Arial, sans-serif;
    pointer-events: none;
}
.share-count{
    margin-left: 2px;
}
.see-more{
    position: absolute;
    bottom: 18px;
    right: 18px;
    color: #fff;
    background: #DE4C80;
    font-size: 12px;
    width: 70px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-family: Roboto,"Helvetica Neue", Helvetica, Arial, sans-serif;
    pointer-events: none;
}
.fb-share:hover, .see-more:hover{
    opacity: .8;
}
.addmobile{
    background: #DE4C80;
    color: #fff;
    font-size: 28px;
    line-height: 14px;
}

.adnow{
    background: #fff;
    margin-top: 30px;
}
.post.gallery img{
    border: 0;
}
.gallery.post h2{
    font-size: 28px;
    margin-top: 0;
}
.gallery.post h2 span{
    color: #de4c80;
}
.gallery h3{
    font-size: 40px;
    font-family: "Avenir","Open Sans",sans-serif;
    font-weight: bold;
    margin-bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #f5f5f5;
}
.post.gallery .gallery-images img{
    border: 2px solid #f5f5f5;
}

.gallery-images{
    display: none;
}
.gallery-images.slick-initialized{
    display: block;
}
.post.gallery .gallery-images .current img{
    border: 2px solid #de4c80;
}
.gallery a.btn.btn-block.btn-lg.btn-primary {
    font-size: 22px;
    padding: 20px;
}
h4.description {
    font-size: 40pt;
    text-align: center;
    font-family: "Avenir","Open Sans","Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
}
i.fa.fa-user-circle {
    background: #de4c80;
    color: #fff;
    font-size: 24px;
    line-height: 38px;
    padding: 0 6px;
    margin: 0 10px;
}
.author {
    position: absolute;
    right: 5px;
    top: 5px;
    border: 1px solid #eee;
    padding: 5px;
}
/*
  Player Skin Designer for Video.js
  http://videojs.com

  To customize the player skin edit
  the CSS below. Click "details"
  below to add comments or questions.
  This file uses some SCSS. Learn more
  at http://sass-lang.com/guide)

  This designer can be linked to at:
  http://codepen.io/heff/pen/EarCt/left/?editors=010
*/
.video-js {
    /* The base font size controls the size of everything, not just text.
       All dimensions use em-based sizes so that the scale along with the font size.
       Try increasing it to 15px and see what happens. */
    font-size: 10px;
    /* The main font color changes the ICON COLORS as well as the text */
    color: #fff;
    background: #fff;
}

/* The "Big Play Button" is the play button that shows before the video plays.
   To center it set the align values to center and middle. The typical location
   of the button is the center, but there is trend towards moving it to a corner
   where it gets out of the way of valuable content in the poster image.*/
.video-js .vjs-big-play-button {
    /* The font size is what makes the big play button...big.
       All width/height values use ems, which are a multiple of the font size.
       If the .video-js font-size is 10px, then 3em equals 30px.*/
    font-size: 6em;
    /* We're using SCSS vars here because the values are used in multiple places.
       Now that font size is set, the following em values will be a multiple of the
       new font size. If the font-size is 3em (30px), then setting any of
       the following values to 3em would equal 30px. 3 * font-size. */
    /* 1.5em = 45px default */
    line-height: 1.43em;
    height: 1.5em;
    width: 1.5em;
    /* 0.06666em = 2px default */
    border: 0.06666em solid #fff;
    /* 0.3em = 9px default */
    border-radius: 3em;
    /* Align center */
    left: 50%;
    top: 50%;
    margin-left: -0.75em;
    margin-top: -0.75em;
}

/* The default color of control backgrounds is mostly black but with a little
   bit of blue so it can still be seen on all-black video frames, which are common. */
.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
    /* IE8 - has no alpha support */
    background-color: #DE4C80;
    /* Opacity: 1.0 = 100%, 0.0 = 0% */
    background-color: rgba(222, 76, 128, 0.7);
}
.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
    outline: 0;
    border-color: #fff;
    /* IE8 - has no alpha support */
    background-color: #EB92B2;
    /* Opacity: 1.0 = 100%, 0.0 = 0% */
    background-color: rgba(222, 76, 128, 0.6);
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
}
/* Slider - used for Volume bar and Progress bar */
.video-js .vjs-slider {
    background-color: #f8dae5;
    background-color: rgba(248, 218, 229, 0.5);
}

/* The slider bar color is used for the progress bar and the volume bar
   (the first two can be removed after a fix that's coming) */
.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
    background: #fff;
}

/* The main progress bar also has a bar that shows how much has been loaded. */
.video-js .vjs-load-progress {
    /* For IE8 we'll lighten the color */
    background: ligthen(#f8dae5, 25%);
    /* Otherwise we'll rely on stacked opacities */
    background: rgba(248, 218, 229, 0.5);
}

/* The load progress bar also has internal divs that represent
   smaller disconnected loaded time ranges */
.video-js .vjs-load-progress div {
    /* For IE8 we'll lighten the color */
    background: ligthen(#f8dae5, 50%);
    /* Otherwise we'll rely on stacked opacities */
    background: rgba(248, 218, 229, 0.75);
}

a.playgif{
    position: absolute;
    top: 50%;
    margin-top: -33px;
    left: 50%;
    margin-left: -33px;
    height: 69px;
    width: 69px;
    text-align: center;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);
    line-height: 69px;
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border-radius: 35px;
    opacity: .9;
    pointer-events: none;
}
div:hover > a.playgif{
    background: rgba(0, 0, 0, 0.75);
}
.sklep-promo h4 {
    margin: 0;
    line-height: 44px;
    padding: 0 20px;
    font-weight: bold;
    font-size: 15px;
}

.sklep-promo a.btn.btn-default {
    margin-top: 5px;
    font-size: 15px;
    border-radius: 3px;
}

.sklep-promo {
    background: #de4c80;
    border: 2px solid #de4c80;
    margin-bottom: 30px;
    padding: 10px 20px;
    color: #fff;
}
.sklep-promo img{
    border: 1px solid #fff;
    border-radius: 50%;
}
.sklep-promo .photos div {
    width: 19.5%;
    margin: 0.25%;
    margin-top: 5px;
    border-radius: 0;
    background-size: cover!important;
    border: 1px solid #fff;
    float: left;
    padding-bottom: 20%;
}

@media (max-width: 767px) {
    body{
        padding-top: 75px;
    }
    .navbar-brand > img {
        height: 33px;
        margin-top: 8px;
        margin-left: -150px;
    }

    .post {
        padding: 15px;
    }
    .navbar-nav {
        margin: 0 -15px;
    }
    .navbar-nav > li > a{
        padding: 5px 10px;
    }
    .navbar-default .navbar-nav > .active > a:after{
        left: 20px;
    }
    .next-prev .col-xs-6{
        width: 50%!important;
    }
    .next-prev-btn{
        width: 100%;
        max-width: 170px;
    }
    .nav.navbar-left > li:first-child > a {
        margin-left: 0px;
    }
    .gallery-images{
        width: 90%;
        margin: auto;
    }
    .gallery a.btn.btn-block.btn-lg.btn-primary {
        font-size: 18px;
        padding: 14px;
    }
    .gallery h3,h4.description {
        font-size: 25px;
    }
    .gallery.post h2 {
        font-size: 20px;
    }
    .sklep-promo a.btn.btn-primary {
        font-size: 11px;
    }

    .sklep-promo h4 {
        font-size: 11px;
        padding: 0;
        line-height: 39px;
    }
}
@media (max-width: 1199px){
    .navbar-nav > li > a{
        padding: 30px 10px;
    }
}
@media (max-width: 991px) and (min-width: 768px){
    .navbar-brand > img {
        height: 33px;
        margin-top: 10px;
        top: 50%;
        left: 50%;
        margin-left: -110px;
    }
    ul.nav.navbar-nav:first-child {
        margin-left: -10px;
    }
}
@media (max-width: 560px) {
    .fb-buttons {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
    .comment-button{
        margin-left: 0;
    }
}

.no-scroll {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.mobile-app-splash-page {
    background-color: #fff;
    color: #000;
    text-align: center;
    overflow: hidden;
    height: 100vh;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}
.mobile-app-splash-page h3 {
    font-size: 24px;
    margin: 30px 16px 16px;
    font-weight: 100;
    line-height: 26px;
    color: #000;
}

.mobile-app-splash-page a.btn {
    background-color: #DE4C80;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    width: 80%;
    display: block;
    margin: 0 auto 16px;
    line-height: 20px;
    padding: 14px 0;
    border-radius: 3px;
    margin-bottom: 35px;
    margin-top: 30px;
}

.mobile-app-splash-page a.btn.sb-close {
    color: rgba(0, 0, 0, 0.6);
    background-color: transparent;
    font-size: 14px;
    border-radius: 3px;
    padding: 10px 0;
    font-weight: 100;
    width: 200px;
}

.mobile-app-splash-page p {
    margin-bottom: 16px;
    font-size: .8em;
    color: #999;
    margin-top: 25px
}

.mobile-app-splash-page a,.mobile-app-splash-page a:visited {
    color: #000;
    text-decoration: none;
}

.mobile-app-splash-page p a {
    color: #999
}

.mobile-app-splash-page p .sb-close {
    color: yellow;
}


.mobile-app-splash-page .device {
    text-align: center;
}

.mobile-app-splash-page .device img {
    width: 70%;
    margin: 5px 0 0;
    padding: 0;
}
button.button_button--SPx18r, input[type=button].button_button--SPx18r,.switch_switch--SPx18r.switch_isSelected--SPx18r .switch_visualizationContainer--SPx18r,.purposes_purposes--SPx18r .purposes_purposeItem--SPx18r.purposes_selectedPurpose--SPx18r{
    background: #de4c80!important;
}
button.button_button--SPx18r:hover, input[type=button].button_button--SPx18r:hover{
    color: #fff!important;
    opacity: 0.8;
}
button.button_button--SPx18r.button_invert--SPx18r, input[type=button].button_button--SPx18r.button_invert--SPx18r,.purposes_purposes--SPx18r .purposes_purposeDetail--SPx18r .purposes_active--SPx18r{
    color: #de4c80!important;
}
button.button_button--SPx18r.button_invert--SPx18r, input[type=button].button_button--SPx18r.button_invert--SPx18r{
    background: #fff!important;
}
.app_gdpr--SPx18r a{
    color: #de4c80!important;
}
.app_gdpr--SPx18r a:hover{
    color: #a83c5b !important;
}
.switch_switch--SPx18r .switch_visualizationGlow--SPx18r{
    background-color: #de4c80!important;
}
div.vendors_vendors--SPx18r .vendors_policy--SPx18r{
    fill: #de4c80!important;
}
.pagebck a{
    text-decoration: underline;
}
#smartbanner { position:absolute; left:0; top:-82px; border-bottom:1px solid #e8e8e8; width:100%; height:78px; font-family:'Helvetica Neue',sans-serif; background:-webkit-linear-gradient(top, #f4f4f4 0%,#cdcdcd 100%); background-image: -ms-linear-gradient(top, #F4F4F4 0%, #CDCDCD 100%); background-image: -moz-linear-gradient(top, #F4F4F4 0%, #CDCDCD 100%); box-shadow:0 1px 2px rgba(0,0,0,0.5); z-index:9998; -webkit-font-smoothing:antialiased; overflow:hidden; -webkit-text-size-adjust:none; display: none; }
#smartbanner, html.sb-animation {-webkit-transition: all .3s ease;}
#smartbanner .sb-container { margin: 0 auto; }
#smartbanner .sb-close { position:absolute; left:5px; top:5px; display:block; border:2px solid #fff; width:14px; height:14px; font-family:'ArialRoundedMTBold',Arial; font-size:15px; line-height:15px; text-align:center; color:#fff; background:#070707; text-decoration:none; text-shadow:none; border-radius:14px; box-shadow:0 2px 3px rgba(0,0,0,0.4); -webkit-font-smoothing:subpixel-antialiased; }
#smartbanner .sb-close:active { font-size:13px; color:#aaa; }
#smartbanner .sb-icon { position:absolute; left:30px; top:10px; display:block; width:57px; height:57px; background:rgba(0,0,0,0.6); background-size:cover; border-radius:10px; box-shadow:0 1px 3px rgba(0,0,0,0.3); }
#smartbanner.no-icon .sb-icon { display:none; }
#smartbanner .sb-info { position:absolute; left:98px; top:18px; width:44%; font-size:11px; line-height:1.2em; font-weight:bold; color:#6a6a6a; text-shadow:0 1px 0 rgba(255,255,255,0.8); }
#smartbanner #smartbanner.no-icon .sb-info { left:34px; }
#smartbanner .sb-info strong { display:block; font-size:13px; color:#4d4d4d; line-height: 18px; }
#smartbanner .sb-info > span { display:block; }
#smartbanner .sb-info em { font-style:normal; text-transform:uppercase; }
#smartbanner .sb-button { position:absolute; right:20px; top:24px; border:1px solid #bfbfbf; padding: 0 10px; min-width: 10%; height:24px; font-size:14px; line-height:24px; text-align:center; font-weight:bold; color:#6a6a6a; background:-webkit-linear-gradient(top, #efefef 0%,#dcdcdc 100%); text-transform:uppercase; text-decoration:none; text-shadow:0 1px 0 rgba(255,255,255,0.8); border-radius:3px; box-shadow:0 1px 0 rgba(255,255,255,0.6),0 1px 0 rgba(255,255,255,0.7) inset; }
#smartbanner .sb-button:active, #smartbanner .sb-button:hover { background:-webkit-linear-gradient(top, #dcdcdc 0%,#efefef 100%); }

#smartbanner .sb-icon.gloss:after { content:''; position:absolute; left:0; top:-1px; border-top:1px solid rgba(255,255,255,0.8); width:100%; height:50%; background:-webkit-linear-gradient(top, rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.2) 100%); border-radius:10px 10px 12px 12px; }

#smartbanner.android { border-color:#212228; background: #3d3d3d url('data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7'); border-top: 5px solid #88B131; box-shadow: none; }
#smartbanner.android .sb-close { border:0; width:17px; height:17px; line-height:17px; color:#b1b1b3; background:#1c1e21; text-shadow:0 1px 1px #000; box-shadow:0 1px 2px rgba(0,0,0,0.8) inset,0 1px 1px rgba(255,255,255,0.3); }
#smartbanner.android .sb-close:active { color:#eee; }
#smartbanner.android .sb-info { color:#ccc; text-shadow:0 1px 2px #000; }
#smartbanner.android .sb-info strong { color:#fff; }
#smartbanner.android .sb-button { min-width: 12%; border:1px solid #DDDCDC; padding:1px; color:#d1d1d1; background: none; border-radius: 0; box-shadow: none; min-height:28px}
#smartbanner.android .sb-button span { text-align: center; display: block; padding: 0 10px; background-color: #42B6C9; background-image: -webkit-gradient(linear,0 0,0 100%,from(#42B6C9),to(#39A9BB)); background-image: -moz-linear-gradient(top,#42B6C9,#39A9BB); text-transform:none; text-shadow:none; box-shadow:none; }
#smartbanner.android .sb-button:active, #smartbanner.android .sb-button:hover { background: none; }
#smartbanner.android .sb-button:active span, #smartbanner.android .sb-button:hover span { background:#2AC7E1; }

#smartbanner.windows .sb-icon { border-radius: 0px; }

#smartbanner-floater.smartbanner {position:fixed;left:0;bottom:0;border-top:1px solid #e8e8e8;width:100%;height:78px;background:-webkit-linear-gradient(top, #f4f4f4 0%,#cdcdcd 100%);background-image: -ms-linear-gradient(top, #F4F4F4 0%, #CDCDCD 100%);background-image: -moz-linear-gradient(top, #F4F4F4 0%, #CDCDCD 100%);box-shadow:0 1px 2px rgba(0,0,0,0.5);z-index:9998;-webkit-font-smoothing:antialiased;overflow:hidden;-webkit-text-size-adjust:none;}
#smartbanner-floater.smartbanner, html.sb-animation {-webkit-transition: all .3s ease;}
#smartbanner-floater.smartbanner .sb-container { margin: 0 auto; }
#smartbanner-floater.smartbanner .sb-close {position:absolute;right: 5px;top: 28px;display:block;border:2px solid #fff;width:14px;height:14px;font-family:'ArialRoundedMTBold',Arial;font-size:15px;line-height:15px;text-align:center;color:#fff;background: #fff;text-decoration:none;text-shadow:none;border-radius:14px;-webkit-font-smoothing:subpixel-antialiased;}
#smartbanner-floater.smartbanner .sb-close:active { font-size:13px; color:#aaa; }
#smartbanner-floater.smartbanner .sb-icon {position:absolute;left: 10px;top:10px;display:block;width:57px;height:57px;background:#fff;background-size:cover;border-radius: 5px;box-shadow:0 1px 3px rgba(0,0,0,0.3);}
#smartbanner-floater.smartbanner.no-icon .sb-icon { display:none; }
#smartbanner-floater.smartbanner .sb-info {position:absolute;left: 71px;top: 24px;width: 39%;font-size:11px;line-height:1.2em;font-weight:bold;color:#6a6a6a;text-align: center;}
#smartbanner-floater.smartbanner #smartbanner-floater.smartbanner.no-icon .sb-info { left:34px; }
#smartbanner-floater.smartbanner .sb-info strong {display:block;font-size:13px;color: #000!important;line-height: 18px;}
#smartbanner-floater.smartbanner .sb-info > span {display:block;font-weight: 100;}
#smartbanner-floater.smartbanner .sb-info em { font-style:normal; text-transform:uppercase; }
#smartbanner-floater.smartbanner .sb-button {position:absolute;right: 30px;top:24px;padding: 0 10px;min-width: 10%;height:24px;font-size:14px;line-height:24px;text-align:center;font-weight:bold;color: #fff;background: #de4c80;text-transform:uppercase;text-decoration:none;}
#smartbanner-floater.smartbanner .sb-button:active, #smartbanner-floater.smartbanner .sb-button:hover { background:-webkit-linear-gradient(top, #dcdcdc 0%,#efefef 100%); }

#smartbanner-floater.smartbanner .sb-icon.gloss:after { content:''; position:absolute; left:0; top:-1px; border-top:1px solid rgba(255,255,255,0.8); width:100%; height:50%; background:-webkit-linear-gradient(top, rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.2) 100%); border-radius:10px 10px 12px 12px; }
#smartbanner-floater.smartbanner.android {background: #ffffff;box-shadow: none;}
#smartbanner-floater.smartbanner.android .sb-close {border:0;width:17px;height:17px;line-height:17px;color: #000000;}
#smartbanner-floater.smartbanner.android .sb-close:active { color:#eee; }
#smartbanner-floater.smartbanner.android .sb-info {color: #000;}
#smartbanner-floater.smartbanner.android .sb-info strong { color:#fff; }
#smartbanner-floater.smartbanner.android .sb-button {min-width: 12%;padding:1px;border-radius: 0;box-shadow: none;min-height:28px;}
#smartbanner-floater.smartbanner.android .sb-button span { text-align: center; display: block; padding: 0 10px; text-transform:none; text-shadow:none; box-shadow:none; }
#smartbanner-floater.smartbanner.android .sb-button:active, #smartbanner-floater.smartbanner.android .sb-button:hover { background: none; }
#smartbanner-floater.smartbanner.android .sb-button:active span, #smartbanner-floater.smartbanner.android .sb-button:hover span { background:#2AC7E1; }

#smartbanner-floater.smartbanner.windows .sb-icon { border-radius: 0px; }

